import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"knowledge-base"}},[_c(VCard,{staticClass:"knowledge-base-bg d-flex align-center justify-center text-center mb-7",attrs:{"flat":""}},[_c(VCardText,[_c('p',{staticClass:"kb-title text-2xl font-weight-semibold primary--text mb-2"},[_vm._v(" Hello, how can we help? ")]),_c('p',{staticClass:"mb-7"},[_vm._v(" or choose a category to quickly find the help you need ")]),_c(VForm,{staticClass:"kb-search-input mx-auto"},[_c(VTextField,{staticClass:"kb-search-input",attrs:{"outlined":"","placeholder":"Ask a question....","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{staticClass:"mx-1",attrs:{"size":"23"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")])]},proxy:true}]),model:{value:(_vm.knowledgeBaseSearchQuery),callback:function ($$v) {_vm.knowledgeBaseSearchQuery=$$v},expression:"knowledgeBaseSearchQuery"}})],1)],1)],1),_c('div',{attrs:{"id":"knowledge-base-content"}},[_c(VRow,{staticClass:"kb-search-content-info match-height"},[_vm._l((_vm.filteredKB),function(item){return _c(VCol,{key:item.character,staticClass:"kb-search-content",attrs:{"md":"4","sm":"6","cols":"12"}},[_c(VCard,{attrs:{"color":"text-center cursor-pointer"},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'page-knowledge-base-category', params: { category: item.category } })}}},[_c('div',{staticClass:"kb-character-wrapper"},[_c(VImg,{staticClass:"mx-auto",attrs:{"contain":"","max-width":item.characterSize,"src":item.character}})],1),_c(VCardTitle,{staticClass:"justify-center pb-3"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VCardText,[_vm._v(_vm._s(item.desc))])],1)],1)}),_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(!_vm.filteredKB.length),expression:"!filteredKB.length"}],staticClass:"text-center",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mt-4"},[_vm._v(" Search result not found!! ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }